import React from 'react';
import './App.css';

function App() {
    return (
        <div className="App">
            <nav className="sticky-nav">
                <ul>
                    <li><a href="#manifesto">Manifesto</a></li>
                    <li><a href="#contact">Contact</a></li>
                </ul>
            </nav>
            <header className="App-header">
            </header>
            <main>
                <section id="manifesto">
                    <h2>Manifesto</h2>
                    <p>
                        Permissionless access to innovative and radical technologies is necessary for the progression of the world. Even more important, the world should welcome innovation and not be scared of whatever the emerging technologies of the decade may be.<br/><br/>

                        After 2020, the world looks different; it has become far more digital than ever. We’re now able to shape the futures of societies, start cultural movements, participate in global financial markets all with a simple internet connection and computer.<br/><br/>

                        While this is positive in many ways, it comes with downsides. The digital realm fails to a degree to foster the camaraderie, spontaneity and nuance we get in meatspace. Our goal as a society, species should be to compound on our progress. We cannot do this if we lose the essence of humanity. The essence that cannot be captured and appreciated over an internet connection.<br/><br/>

                        The Philanthropic Organisation of Radical Technologists (The PORT) has a single goal: Accelerate human progress and technological advancement by fostering innovation and collaboration between radical thinking technologists.

                        So we ask you to join us in this mission to accelerate progress of technologies that will leave a positive impact on the world.
                    </p>
                </section>
                <section id="contact">
                    <h2>Contact</h2>
                    <p>Get in touch at zano@theport.dev</p>
                </section>
            </main>
        </div>
    );
}

export default App;
